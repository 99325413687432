import { useQuery } from "@tanstack/react-query";
import {createAxiosWithAuth} from "./middleware";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const useAuthQuery = (queryKey, config = {}, selectFn = (data) => data) => {
  const navigate = useNavigate();

  const axios = createAxiosWithAuth();

  const response = useQuery({
    queryKey,
    queryFn: async () => await axios(config),
    select: selectFn,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
   if (response.isError) {
      navigate("/login");
    }
  }, [response.isError, navigate]);

  return response
};
