
const columnWidth = "auto";

export const columnsConfig = [
  {
    dataField: "PokerNetwork",
    dataType: "string",
    caption: "main.dataTable.poker_network",
    name: "main.dataTable.poker_network",
    groupIndex: 0,
    autoExpandGroup: true,
    width: columnWidth,
    translate: true,
  },
  {
    dataField: "BigBlind",
    dataType: "number",
    caption: "main.dataTable.bbChips",
    name: "main.dataTable.bbChips",
    groupIndex: 1,
    autoExpandGroup: false,
    width: columnWidth,
    translate: true,
  },
  {
    dataField: "GameType",
    dataType: "string",
    caption: "main.dataTable.game_type",
    name: "main.dataTable.game_type",
    width: columnWidth,
    translate: true,
  },
  // {
  //   dataField: "NormalLimit",
  //   dataType: "string",
  //   visible: false,
  //   caption: "Normal limit",
  //   name: "Normal limit",
  //   width: columnWidth,
  //   translate: false,
  // },
  {
    dataField: "UserID",
    dataType: "string",
    visible: false,
    caption: "main.dataTable.login",
    name: "main.dataTable.login",
    width: columnWidth,
    translate: true,
  },
  {
    dataField: "PlayerID",
    dataType: "string",
    visible: false,
    caption: "main.dataTable.PID",
    name: "main.dataTable.PID",
    width: columnWidth,
    translate: true,
  },
  // {
  //   dataField: "Room",
  //   dataType: "number",
  //   visible: false,
  //   caption: "main.dataTable.room",
  //   name: "main.dataTable.room",
  //   width: columnWidth,
  //   translate: true,
  // },
  {
    dataField: "ClubID",
    dataType: "string",
    visible: false,
    caption: "main.dataTable.club",
    name: "main.dataTable.club",
    width: columnWidth,
    translate: true,
  },
  {
    dataField: "LeagueID",
    dataType: "string",
    visible: false,
    caption: "main.dataTable.leagueID",
    name: "main.dataTable.leagueID",
    width: columnWidth,
    translate: true,
  },
  {
    dataField: "SuperLeagueID",
    dataType: "string",
    visible: false,
    caption: "main.dataTable.superleagueID",
    name: "main.dataTable.superleagueID",
    width: columnWidth,
    translate: true,
  },
  {
    dataField: "Date",
    dataType: "date",
    format: "dd/MM/yyyy",
    visible: false,
    caption: "main.dataTable.date",
    name: "main.dataTable.date",
    translate: true,
  },
  {
    dataField: "Hands",
    dataType: "number",
    caption: "main.dataTable.hands",
    name: "main.dataTable.hands",
    translate: true,
  },
  {
    dataField: "NetWinUsd",
    dataType: "number",
    caption: "Net win, $",
    name: "Net win, $",
    translate: false,
  },
  {
    dataField: "AllinEVUsd",
    dataType: "number",
    caption: "all-in EV, $",
    name: "all-in EV, $",
    translate: false,
  },
  {
    dataField: "NetWin",
    dataType: "number",
    visible: false,
    caption: "Net win (chips)",
    name: "Net win (chips)",
    translate: false,
  },
  {
    dataField: "AllinEV",
    dataType: "number",
    visible: false,
    caption: "all-in EV (chips)",
    name: "all-in EV (chips)",
    translate: false,
  },
  {
    dataField: "Fuel",
    dataType: "number",
    caption: "main.dataTable.fuel",
    name: "main.dataTable.fuel",
    translate: true,
  },
  {
    dataField: "NetWinrate",
    dataType: "number",
    caption: "bb/100",
    name: "bb/100",
    translate: false,
  },
  {
    dataField: "AllinEVWinrate",
    dataType: "number",
    caption: "EV bb/100",
    name: "EV bb/100",
    translate: false,
  },
  {
    dataField: "HandsHU",
    dataType: "number",
    caption: "main.dataTable.HUHands",
    name: "main.dataTable.HUHands",
    translate: true,
  },
  {
    dataField: "HUPercent",
    dataType: "number",
    caption: "%HU",
    name: "%HU",
    customizeText: (cellInfo) => cellInfo.value + " %",
    translate: false,
  },
  {
    dataField: "HandsOperatorErrors",
    dataType: "number",
    caption: "main.dataTable.errorsOperator",
    name: "main.dataTable.errorsOperator",
    translate: true,
  },
  {
    dataField: "Error.percent",
    dataType: "number",
    caption: "main.dataTable.error",
    name: "main.dataTable.error",
    customizeText: (cellInfo) => cellInfo.value + " %",
    translate: true,
  },
  {
    dataField: "redBlackTables",
    dataType: "number",
    caption: "main.dataTable.R/BTables",
    name: "main.dataTable.R/BTables",
    translate: true,
  },
  {
    dataField: "redBlackTablesPercent",
    dataType: "number",
    caption: "main.dataTable.R/BTablesPercent",
    name: "main.dataTable.R/BTablesPercent",
    customizeText: (cellInfo) => cellInfo.value + " %",
    translate: true,
  },
];
