import React, { useState, useEffect } from "react";
import "./toolbar.scss";
import {
  Dropdown,
  Button,
  MultiSelect,
  DatePicker,
  DatePickerInput,
  FilterableMultiSelect,
  DropdownSkeleton,
} from "@carbon/react";
import { useTranslation } from "react-i18next";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { dates } from "../../../consts/dates";
import { formatDatePreset } from "../../../utils/formatDatePreset";

export default function Toolbar({
  setFilterOptions,
  pokerNetworks,
  gameTypes,
  logins,
  loginsData,
  clubs,
  leagues,
  players,
  dataGridRef,
  isPending,
}) {
  const version = process.env.REACT_APP_VERSION;
  const { t, i18n } = useTranslation();

  const [dateFromTo, setDateFromTo] = useState(
    (localStorage.getItem("storedDateFromTo") &&
      JSON.parse(localStorage.getItem("storedDateFromTo")).map(
        (dateString) => new Date(dateString)
      )) ||
      []
  );

  const [datePreset, setDatePreset] = useState(
    JSON.parse(localStorage.getItem("storedDatePreset")) || {
      id: "1",
      text: "main.toolbar.date.presetDate.month",
    }
  );

  const [selectedPokerNetworks, setSelectedPokerNetworks] = useState(
    JSON.parse(localStorage.getItem("storedPokerNetwork")) || []
  );

  const [isUpdatedNetwork, setIsUpdatedNetwork] = useState(
    `network_${new Date().getTime()}`
  );

  const [selectedGameTypes, setSelectedGameTypes] = useState(
    JSON.parse(localStorage.getItem("storedGameType")) || []
  );
  
  const [isUpdatedType, setIsUpdatedType] = useState(
    `type_${new Date().getTime()}`
  );

  const [selectedLogins, setSelectedLogins] = useState(
    JSON.parse(localStorage.getItem("storedLogin")) || []
  );

  const [selectedLeagues, setSelectedLeagues] = useState(
    JSON.parse(localStorage.getItem("storedLeague")) || []
  );

  const [isUpdatedLeague, setIsUpdatedLeague] = useState(
    `league_${new Date().getTime()}`
  );

  const [selectedClubs, setSelectedClubs] = useState(
    JSON.parse(localStorage.getItem("storedClub")) || []
  );

  const [isUpdatedClub, setIsUpdatedClub] = useState(
    `club_${new Date().getTime()}`
  );

  const [selectedPIDs, setSelectedPIDs] = useState(
    JSON.parse(localStorage.getItem("storedPID")) || []
  );
  
  const [isUpdatedPID, setIsUpdatedPID] = useState(
    `PID_${new Date().getTime()}`
  );

  const [networksList, setNetworksList] = useState([]);
  const [gameTypesList, setGameTypesList] = useState([]);
  const [leaguesList, setLeaguesList] = useState([]);
  const [clubsList, setClubsList] = useState([]);
  const [playersList, setPlayersList] = useState([]);

  const [isUpdateList, setIsUpdateList] = useState(false);
  const [isRenderMultiSelect, setIsRenderMultiSelect] = useState(false);

  const handleFilteredList = (selectedItems) => {
    const combinedData = selectedItems.reduce(
      (lists, login) => {
        const loginData = loginsData[login];

        if (loginData) {
          lists.networksSet.push(...loginData.pokerNetworks);
          lists.gameTypesSet.push(...loginData.gameTypes);
          lists.leaguesSet.push(...loginData.leagues);
          lists.clubsSet.push(...loginData.clubs);
          lists.playersSet.push(...loginData.players);
        }

        return lists;
      },
      {
        networksSet: [],
        gameTypesSet: [],
        leaguesSet: [],
        clubsSet: [],
        playersSet: [],
      }
    );

    setNetworksList([...new Set(combinedData.networksSet)]);
    setGameTypesList([...new Set(combinedData.gameTypesSet)]);
    setLeaguesList([...new Set(combinedData.leaguesSet)]);
    setClubsList([...new Set(combinedData.clubsSet)]);
    setPlayersList([...new Set(combinedData.playersSet)]);
  };

  const handleLoginChange = (selectedItems) => {
    setSelectedLogins(selectedItems);

    if (selectedItems.length > 0) {
      handleFilteredList(selectedItems);

      setIsUpdateList(true);
    } else {
      setNetworksList([]);
      setGameTypesList([]);
      setLeaguesList([]);
      setClubsList([]);
      setPlayersList([]);
    }
  };

  const handleExport = () => {
    if (dataGridRef.current) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");

      exportDataGrid({
        component: dataGridRef.current.instance,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "DataGrid.xlsx"
          );
        });
      });
    }
  };

  useEffect(() => {
    if (selectedLogins.length > 0 && Object.keys(loginsData).length > 0) {
      handleFilteredList(selectedLogins);
    }
  }, [loginsData]);

  useEffect(() => {
    if (isUpdateList) {
      const selectedPokerNetworksFromLogins = selectedPokerNetworks.filter(
        (network) => networksList.includes(network)
      );

      setSelectedPokerNetworks(selectedPokerNetworksFromLogins);

      const selectedGameTypesFromLogins = selectedGameTypes.filter((type) =>
        gameTypesList.includes(type)
      );

      setSelectedGameTypes(selectedGameTypesFromLogins);

      const selectedLeaguesFromLogins = selectedLeagues.filter((league) =>
        leaguesList.includes(league)
      );

      setSelectedLeagues(selectedLeaguesFromLogins);

      const selectedClubsFromLogins = selectedClubs.filter((club) =>
        clubsList.includes(club)
      );

      setSelectedClubs(selectedClubsFromLogins);

      const selectedPIDsFromLogins = selectedPIDs.filter((PID) =>
        playersList.includes(PID)
      );

      setSelectedPIDs(selectedPIDsFromLogins);

      setIsUpdateList(false);
      setIsRenderMultiSelect(true);
    }
  }, [isUpdateList]);

  useEffect(() => {
    if (isRenderMultiSelect) {
      setIsUpdatedNetwork(`network_${new Date().getTime()}`);
      setIsUpdatedType(`type_${new Date().getTime()}`);
      setIsUpdatedLeague(`league_${new Date().getTime()}`);
      setIsUpdatedClub(`club_${new Date().getTime()}`);
      setIsUpdatedPID(`PID_${new Date().getTime()}`);

      setIsRenderMultiSelect(false);
    }
  }, [isRenderMultiSelect]);

  useEffect(() => {
    localStorage.setItem(
      "storedDateFromTo",
      JSON.stringify(dateFromTo.map((date) => new Date(date).toString()))
    );
  }, [dateFromTo]);

  useEffect(() => {
    localStorage.setItem(
      "storedPokerNetwork",
      JSON.stringify(selectedPokerNetworks)
    );
  }, [selectedPokerNetworks]);

  useEffect(() => {
    localStorage.setItem("storedGameType", JSON.stringify(selectedGameTypes));
  }, [selectedGameTypes]);

  useEffect(() => {
    localStorage.setItem("storedLogin", JSON.stringify(selectedLogins));
  }, [selectedLogins]);

  useEffect(() => {
    localStorage.setItem("storedPID", JSON.stringify(selectedPIDs));
  }, [selectedPIDs]);

  useEffect(() => {
    localStorage.setItem("storedClub", JSON.stringify(selectedClubs));
  }, [selectedClubs]);

  useEffect(() => {
    localStorage.setItem("storedLeague", JSON.stringify(selectedLeagues));
  }, [selectedLeagues]);

  useEffect(() => {
    localStorage.setItem("storedDatePreset", JSON.stringify(datePreset));
  }, [datePreset]);

  useEffect(() => {
    if (datePreset.id !== "3") {
      setDateFromTo(formatDatePreset(datePreset));
    }
  }, [datePreset]);

  useEffect(() => {
    setFilterOptions({
      dateFromTo,
      selectedPokerNetworks,
      selectedGameTypes,
      selectedLogins,
      selectedPIDs,
      selectedClubs,
      selectedLeagues,
    });
  }, [
    dateFromTo,
    selectedPokerNetworks,
    selectedGameTypes,
    selectedLogins,
    selectedPIDs,
    selectedClubs,
    selectedLeagues,
    setFilterOptions,
  ]);

  return (
    <div className="user_toolbar">
      <div className="user_toolbar_filters">
        <DatePicker
          key={i18n.language}
          datePickerType="range"
          minDate={(function () {
            var date = new Date();
            date.setMonth(date.getMonth() - 3);
            date.setHours(0, 0, 0, 0);
            return date.toISOString();
          })()}
          maxDate={new Date().setDate(new Date().getDate())}
          dateFormat={i18n.language.slice(0, 2) === "zh" ? "Y/m/d" : "d/m/Y"}
          locale={i18n.language.slice(0, 2)}
          value={dateFromTo}
          onChange={(selectedDate) => {
            setDateFromTo(selectedDate);
            setDatePreset({
              id: "3",
              text: "main.toolbar.date.datePreset",
            });
          }}
        >
          <DatePickerInput
            id="date-picker-input-id-start"
            placeholder={t("main.toolbar.date.from")}
            size="lg"
            labelText=""
          />
          <DatePickerInput
            id="date-picker-input-id-finish"
            placeholder={t("main.toolbar.date.to")}
            size="lg"
            labelText=""
          />
        </DatePicker>
        <Dropdown
          titleText=""
          id="date"
          label={t("main.toolbar.date.datePreset")}
          items={dates}
          itemToString={(item) => (item?.text ? t(item.text) : "")}
          size="lg"
          selectedItem={datePreset}
          initialSelectedItem={datePreset}
          onChange={({ selectedItem }) => setDatePreset(selectedItem)}
          translateWithId={(messageId) => {
            if (messageId === "close.menu")
              return t("main.toolbar.tooltips.closeMenu");
            if (messageId === "open.menu")
              return t("main.toolbar.tooltips.openMenu");
          }}
        />
        {!isPending ? (
          <MultiSelect
            key={isUpdatedNetwork}
            id="poker_network"
            label={t("main.toolbar.poker_network")}
            items={networksList.length ? networksList : pokerNetworks}
            initialSelectedItems={selectedPokerNetworks}
            size="lg"
            onChange={(e) => setSelectedPokerNetworks(e.selectedItems)}
            translateWithId={(messageId) => {
              if (messageId === "close.menu")
                return t("main.toolbar.tooltips.closeMenu");
              if (messageId === "open.menu")
                return t("main.toolbar.tooltips.openMenu");
              if (messageId === "clear.all")
                return t("main.toolbar.tooltips.clearAll");
            }}
          />
        ) : (
          <DropdownSkeleton size="lg" />
        )}
        {!isPending ? (
          <MultiSelect
            key={isUpdatedType}
            id="game_type"
            label={t("main.toolbar.game_type")}
            items={gameTypesList.length ? gameTypesList : gameTypes}
            initialSelectedItems={selectedGameTypes}
            size="lg"
            onChange={(e) => setSelectedGameTypes(e.selectedItems)}
            translateWithId={(messageId) => {
              if (messageId === "close.menu")
                return t("main.toolbar.tooltips.closeMenu");
              if (messageId === "open.menu")
                return t("main.toolbar.tooltips.openMenu");
              if (messageId === "clear.all")
                return t("main.toolbar.tooltips.clearAll");
            }}
          />
        ) : (
          <DropdownSkeleton size="lg" />
        )}
        {!isPending ? (
          <FilterableMultiSelect
            id="login"
            label={t("main.toolbar.login")}
            placeholder={t("main.toolbar.login")}
            size="lg"
            itemToString={(item) => (item ? `${item}` : "")}
            items={logins}
            initialSelectedItems={selectedLogins}
            onChange={(e) => handleLoginChange(e.selectedItems)}
            translateWithId={(messageId) => {
              if (messageId === "close.menu")
                return t("main.toolbar.tooltips.closeMenu");
              if (messageId === "open.menu")
                return t("main.toolbar.tooltips.openMenu");
              if (messageId === "clear.all")
                return t("main.toolbar.tooltips.clearAll");
            }}
          />
        ) : (
          <DropdownSkeleton size="lg" />
        )}
        {!isPending ? (
          <FilterableMultiSelect
            key={isUpdatedPID}
            id="PID"
            label={t("main.toolbar.PID")}
            placeholder={t("main.toolbar.PID")}
            size="lg"
            itemToString={(item) => (item ? `${item}` : "")}
            items={playersList.length ? playersList : players}
            initialSelectedItems={selectedPIDs}
            onChange={(e) => setSelectedPIDs(e.selectedItems)}
            translateWithId={(messageId) => {
              if (messageId === "close.menu")
                return t("main.toolbar.tooltips.closeMenu");
              if (messageId === "open.menu")
                return t("main.toolbar.tooltips.openMenu");
              if (messageId === "clear.all")
                return t("main.toolbar.tooltips.clearAll");
            }}
          />
        ) : (
          <DropdownSkeleton size="lg" />
        )}
        {!isPending ? (
          <FilterableMultiSelect
            key={isUpdatedClub}
            id="club"
            label={t("main.dataTable.club")}
            placeholder={t("main.dataTable.club")}
            itemToString={(item) =>
              item ? `${item}` : t("main.toolbar.noClub")
            }
            items={clubsList.length ? clubsList : clubs}
            initialSelectedItems={selectedClubs}
            size="lg"
            onChange={(e) => setSelectedClubs(e.selectedItems)}
            translateWithId={(messageId) => {
              if (messageId === "close.menu")
                return t("main.toolbar.tooltips.closeMenu");
              if (messageId === "open.menu")
                return t("main.toolbar.tooltips.openMenu");
              if (messageId === "clear.all")
                return t("main.toolbar.tooltips.clearAll");
            }}
          />
        ) : (
          <DropdownSkeleton size="lg" />
        )}
        {!isPending ? (
          <FilterableMultiSelect
            key={isUpdatedLeague}
            id="league"
            label={t("main.toolbar.league")}
            placeholder={t("main.toolbar.league")}
            itemToString={(item) =>
              item ? `${item}` : t("main.toolbar.noLeague")
            }
            items={leaguesList.length ? leaguesList : leagues}
            initialSelectedItems={selectedLeagues}
            size="lg"
            onChange={(e) => setSelectedLeagues(e.selectedItems)}
            translateWithId={(messageId) => {
              if (messageId === "close.menu")
                return t("main.toolbar.tooltips.closeMenu");
              if (messageId === "open.menu")
                return t("main.toolbar.tooltips.openMenu");
              if (messageId === "clear.all")
                return t("main.toolbar.tooltips.clearAll");
            }}
          />
        ) : (
          <DropdownSkeleton size="lg" />
        )}
      </div>
      <Button
        className={`excelExport ${version === "NZT" ? "nzt" : "jin"}`}
        onClick={handleExport}
      >
        {t("main.toolbar.XLSExport")}
      </Button>
    </div>
  );
}
