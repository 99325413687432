
export const cellColorPrepared = (e) => {
   if (e.rowType === "data") {
     if (
       e.column.dataField === "redBlackTablesPercent" &&
       e.data.redBlackTablesPercent > 5
     ) {
       e.cellElement.style.cssText =
         "background-color: rgba(255, 241, 241, 1)";
     }
     if (e.column.dataField === "Error.percent" && e.data.Error.percent > 1) {
       e.cellElement.style.cssText =
         "background-color: rgba(255, 241, 241, 1)";
     }
   }
 };