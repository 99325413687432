import React, { useEffect, useState } from "react";
import {
  FormGroup,
  TextInput,
  PasswordInput,
  Button,
  Link,
  InlineNotification,
} from "@carbon/react";
import { ArrowRight } from "@carbon/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin";
import validator from "validator";

export default function LoginForm() {
  const version = process.env.REACT_APP_VERSION;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [validation, setValidation] = useState({
    invalid: false,
    invalidText: "",
  });
  const [notification, setNotification] = useState({
    visibility: false,
    title: "",
    subtitle: "",
    kind: "",
  });

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const mutation = useLogin();

  const handleSubmit = (e) => {
    e.preventDefault();

    mutation.mutate({ login, password });
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      navigate("/");
    }
  }, [mutation.isSuccess, navigate]);

  useEffect(() => {
    if (mutation.isError) {
      if (mutation.error?.response?.status === 401) {
        setNotification({
          visibility: true,
          title: "authentication.notification.authError401.title",
          subtitle: "authentication.notification.authError401.authError",
          kind: "error",
        });
        
        return;
      }
      setNotification({
        visibility: true,
        title: "authentication.notification.requestError500.title",
        subtitle: "authentication.notification.requestError500.serverError",
        kind: "error",
      });
    }
  }, [mutation.isError, setNotification]);

  useEffect(() => {
    if (login && !validator.isEmail(login)) {
      setValidation({
        invalid: true,
        invalidText: "authentication.validationErrors.emailValidation",
      });
    } else {
      setValidation({ invalid: false, invalidText: "" });
    }
  }, [login, setValidation]);

  return (
    <FormGroup
      className={`formGroup ${version === "NZT" ? "nzt" : "jin"}`}
      legendText=""
    >
      <h3>
        {version === "NZT"
          ? t("authentication.defaultForm.titleNZT")
          : t("authentication.defaultForm.titleJIN")}
      </h3>
      <form onSubmit={handleSubmit}>
        <TextInput
          id="one"
          labelText={t("authentication.common.login")}
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          required
          invalid={validation.invalid}
          invalidText={validation.invalidText ? t(validation.invalidText) : ""}
        />
        <PasswordInput
          required
          id="two"
          labelText={t("authentication.common.password")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Link onClick={() => navigate("/reset-password")} className="formLink">
          {t("authentication.defaultForm.forgotPassword")}
        </Link>
        {notification.visibility && (
          <InlineNotification
            title={t(notification.title)}
            subtitle={t(notification.subtitle)}
            kind={notification.kind}
            onClose={() =>
              setNotification({
                visibility: false,
                title: "",
                subtitle: "",
                kind: "",
              })
            }
            lowContrast={true}
            aria-label={t("authentication.notification.closeNotification")}
            className="notification"
          />
        )}
        <Button
          type="submit"
          className={`formButton ${version === "NZT" ? "nzt" : "jin"}`}
          renderIcon={ArrowRight}
          iconDescription="ArrowRight"
        >
          {t("authentication.common.continue")}
        </Button>
      </form>
    </FormGroup>
  );
}
