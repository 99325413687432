import React, { useEffect, useState } from "react";
import {
  FormGroup,
  TextInput,
  Button,
  ButtonSet,
  InlineNotification,
} from "@carbon/react";
import { ArrowRight } from "@carbon/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { useResetPassword } from "../../hooks/useResetPassword";

export default function ResetPasswordForm() {
  const { t } = useTranslation();
  const version = process.env.REACT_APP_VERSION;
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [notification, setNotification] = useState({
    visibility: false,
    title: "",
    subtitle: "",
    kind: "",
  });

  const mutation = useResetPassword();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!invalid) {
      mutation.mutate({ login: userEmail });
    }
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      setNotification({
        visibility: true,
        title: "authentication.notification.success.title",
        subtitle: "authentication.notification.success.subTitle",
        kind: "success",
      });
    }
  }, [mutation.isSuccess, setNotification]);

  useEffect(() => {
    if (mutation.isError) {
      setNotification({
        visibility: true,
        title: "authentication.notification.requestError500.title",
        subtitle: "authentication.notification.requestError500.serverError",
        kind: "error",
      });
    }
  }, [mutation.isError, setNotification]);

  useEffect(() => {
    if (userEmail && !validator.isEmail(userEmail)) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [userEmail, setInvalid]);

  return (
    <FormGroup className="formGroup" legendText="">
      <h3>{t("authentication.forgetForm.title")}</h3>
      <p>
        {version === "NZT"
          ? t("authentication.forgetForm.subTitleNZT")
          : t("authentication.forgetForm.subTitleJIN")}
      </p>
      <form onSubmit={handleSubmit}>
        <TextInput
          id="three"
          labelText={t("authentication.common.email")}
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          required
          invalid={invalid}
          invalidText={t("authentication.validationErrors.emailValidation")}
        />
        {notification.visibility && (
          <InlineNotification
            title={t(notification.title)}
            subtitle={t(notification.subtitle)}
            kind={notification.kind}
            onClose={() =>
              setNotification({
                visibility: false,
                title: "",
                subtitle: "",
                kind: "",
              })
            }
            lowContrast={true}
            aria-label={t("authentication.notification.closeNotification")}
            className="notification"
          />
        )}
        <ButtonSet className="formButtonSet">
          <Button
            className="formButtonCancel"
            onClick={() => navigate("/login")}
            kind="tertiary"
          >
            {t("authentication.common.cancel")}
          </Button>
          <Button
            type="submit"
            className={`formButton ${version === "NZT" ? "nzt" : "jin"}`}
            renderIcon={ArrowRight}
            iconDescription="ArrowRight"
          >
            {t("authentication.common.continue")}
          </Button>
        </ButtonSet>
      </form>
    </FormGroup>
  );
}
