import axios from "axios";

export function createAxiosWithAuth() {
  const authMiddleware = (config) => {
    const token = localStorage.getItem("authToken");

    if (token) {
      config.headers.PortalAuth = token;
    }

    return config;
  };

  const axiosInstanceWithAuth = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  axiosInstanceWithAuth.interceptors.request.use(authMiddleware);

  return axiosInstanceWithAuth;
}

export function createAxios() {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  return axiosInstance;
}
