import React, { useEffect, useState } from "react";
import "./login.scss";
import logoNZT from "../../assets/nzt/authLogo.svg";
import logoJIN from "../../assets/jin/authLogo.svg";
import SetupPasswordForm from "../../components/authForms/SetupPasswordForm";
import { useNavigate, useLocation } from "react-router-dom";

export default function SetupPasswordPage() {
  const version = process.env.REACT_APP_VERSION;

  const location = useLocation();
  const navigate = useNavigate();

  const [resetToken, setResetToken] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get("resetToken");

    if (!tokenFromUrl) {
      navigate("/error");
    }

    setResetToken(tokenFromUrl);
  }, []);

  return (
    <div className={`auth ${version === "NZT" ? "nzt" : "jin"}`}>
      <div className="group">
        <img src={version === "NZT" ? logoNZT : logoJIN} alt="logo" />
        <div className="bg">
          <SetupPasswordForm resetToken={resetToken} />
        </div>
      </div>
    </div>
  );
}
