export const tableDataMapper = (filteredData) => {
  const newData = filteredData?.map((item, index) => ({
    ...item,
    ID: index,
    redBlackTables: `${item.HandsQBlack} / ${item.HandsQRed}`,
    redBlackTablesPercent: calculatePercent(
      item.HandsQBlack + item.HandsQRed,
      item.Hands
    ),
    HUPercent: calculatePercent(item.HandsHU, item.Hands),
    Error: {
      percent: calculatePercent(item.HandsOperatorErrors, item.Hands),
      errors: item.HandsOperatorErrors,
      hands: item.Hands,
    },
  }));

  return newData;
};

const calculatePercent = (a, b) => {
  return ((a / b) * 100) % 1 === 0 ? (a / b) * 100 : ((a / b) * 100).toFixed(2);
};
