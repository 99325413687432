import { useMutation } from "@tanstack/react-query"
import {createAxios} from './middleware'

export const useResetPassword = () =>{   
  const axios = createAxios();

   return useMutation({
      mutationFn: async (query) => {
        return await axios.post('/resetPwd', query)
      },
    })
}