export const filteredDataMapper = (mappedData, filterOptions) => {
  if (!mappedData.length) {
    return [];
  }

  const {
    dateFromTo,
    selectedPokerNetworks,
    selectedGameTypes,
    selectedLogins,
    selectedPIDs,
    selectedClubs,
    selectedLeagues,
  } = filterOptions;

  const makeDataFilterFn = (dateArray) => (row) =>
    new Date(row["Date"]).setHours(0, 0, 0, 0) >= dateArray[0] &&
    new Date(row["Date"]).setHours(0, 0, 0, 0) <= dateArray[1];

  const makeMultiSelectFilterFn = (fieldKey, selectArray) => (row) =>
    !selectArray.length || selectArray.includes(row[fieldKey]);

  const filterHandlers = [
    makeDataFilterFn(dateFromTo),
    makeMultiSelectFilterFn("PokerNetwork", selectedPokerNetworks),
    makeMultiSelectFilterFn("GameType", selectedGameTypes),
    makeMultiSelectFilterFn("UserID", selectedLogins),
    makeMultiSelectFilterFn("PlayerID", selectedPIDs),
    makeMultiSelectFilterFn("ClubID", selectedClubs),
    makeMultiSelectFilterFn("LeagueID", selectedLeagues),
  ];

  const filteredData = mappedData.filter((row) => {
    return filterHandlers.every((filterFn) => filterFn(row));
  });

  return filteredData;
};
