
export const dates = [
   {
     id: "0",
     text: "main.toolbar.date.presetDate.week",
   },
   {
     id: "1",
     text: "main.toolbar.date.presetDate.month",
   },
   {
     id: "2",
     text: "main.toolbar.date.presetDate.3month",
   },
 ];