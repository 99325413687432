
export const calculateWeightedAverage = (options, percent, hands) => {
   switch (options.summaryProcess) {
     case "start":
       options.totalValue = { paramWeight: 0, hands: 0 };

       break;
     case "calculate":
       options.totalValue.paramWeight += Number(hands) * Number(percent);
       options.totalValue.hands += Number(hands);

       break;
     case "finalize":
       if (options.totalValue.hands !== 0) {
         options.totalValue =
           options.totalValue.paramWeight / options.totalValue.hands;
       } else {
         options.totalValue = 0;
       }
       break;
     default:
       break;
   }
 };