import React from "react";
import "./informationModal.scss";
import { Modal, UnorderedList, ListItem } from "@carbon/react";

export default function InformationModal({
  informationModalVisibility,
  setInformationModalVisibility,
}) {
  return (
    <Modal
      open={informationModalVisibility}
      onRequestClose={() => setInformationModalVisibility(false)}
      modalHeading="Table description"
      modalLabel="Information"
      passiveModal
    >
      <UnorderedList>
        <ListItem>
          <span>
            <b>Game type</b> тип игры (например NL, PLO и тд)
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>Hands</b> количество сыгранных раздач
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>NET win, $</b> фактический выигрыш в USD
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>All-in EV/100, $</b> ожидаемая прибыль (EV) в USD (т.е. ситуации,
            где все участники раздачи пошли “ALL IN” рассчитываются в
            соответствии с математическим ожиданием. При сопоставлении с “NET
            win” может служить индикатором насколько вам везет/не везет в ALL IN
            ситуациях.)
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>Fuel</b> потраченное топливо
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>BB/100</b> фактическая производительность прибыли, которая
            показывает размер выигрыша в больших блаиндах на каждые 100
            раздачфактический темп роста прибыли, выраженный в количестве
            выигранных больших блаиндов на каждые 100 раздач
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>EV BB/100</b> ожидаемый темп роста прибыли, выраженный в
            количестве выигранных больших блаиндов на каждые 100 раздач (т.е.
            ситуации, где все участники раздачи пошли “ALL IN” рассчитываются в
            соответствии с математическим ожиданием. При сопоставлении с
            “BB/100” может служить индикатором насколько вам везет/не везет в
            ALL IN ситуациях)
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>HU hands</b> кол-во рук сыгранных в ХэдсАп (1 на 1)
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>%HU</b> кол-во рук сыгранных в ХэдсАп (1 на 1) в %
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>Errors operator</b> количество раздач, когда пользователь сыграл
            не по предоставленной подсказке
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>Errors</b> процент раздач, когда пользователь сыграл не по
            предоставленной подсказке
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>Red/Black tables</b> кол-во рук за красными и черными столами
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>Red/Black tables %</b> кол-во рук за красными и черными столами в
            %
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>Poker network</b> название покерной платформы
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>Big blind (chips)</b> Большой блайнд в фишках
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>Login</b> Логин NZT
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>PID</b> Player ID (игровой аккаунт в руме)
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>Club</b> покерный клуб
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>League ID</b> покерная лига (объединение нескольких клубов)
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>SuperLeague ID</b> покерная суперлига (объединение нескольких
            лиг)
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>Net win (chips)</b> фактический выигрышь в фишках
          </span>
        </ListItem>
        <ListItem>
          <span>
            <b>all-in EV (chips)</b> ожидаемый выигрышь в фишках
          </span>
        </ListItem>
      </UnorderedList>
    </Modal>
  );
}
