
export const formatDatePreset = (filter) => {
   const currentDate = new Date();

   switch (filter.id) {
     case "0":
       let fromWeek = new Date(
         currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
       ).setHours(0, 0, 0, 0);

       return [fromWeek, currentDate];
     case "1":
       let fromMonth = new Date(currentDate);
       fromMonth.setMonth(fromMonth.getMonth() - 1);
       fromMonth.setHours(0, 0, 0, 0);

       return [fromMonth, currentDate];
     case "2":
       let fromMonths = new Date(currentDate);
       fromMonths.setMonth(fromMonths.getMonth() - 3);
       fromMonths.setHours(0, 0, 0, 0);

       return [fromMonths, currentDate];
     default:
       return [];
   }
 };