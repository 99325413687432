import React from "react";
import "./login.scss";
import logoNZT from "../../assets/nzt/authLogo.svg";
import logoJIN from "../../assets/jin/authLogo.svg";
import ResetPasswordForm from "../../components/authForms/ResetPasswordForm";

export default function ResetPasswordPage() {
  const version = process.env.REACT_APP_VERSION;

  return (
    <div className={`auth ${version === "NZT" ? "nzt" : "jin"}`}>
      <div className="group">
        <img src={version === "NZT" ? logoNZT : logoJIN} alt="logo" />
        <div className="bg">
          <ResetPasswordForm/>
        </div>
      </div>
    </div>
  );
}
