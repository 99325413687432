import { useMutation } from "@tanstack/react-query"
import {createAxiosWithAuth} from './middleware'

export const useDeleteTemplate = () =>{   
  const axios = createAxiosWithAuth();

   return useMutation({
      mutationFn: async (query) => {
        return await axios.post('/uiSettings/del', query)
      },
    })
}