import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./i18n";
import LoginPage from "./pages/login/LoginPage";
import UserPage from "./pages/user/UserPage";
import ResetPasswordPage from "./pages/login/ResetPasswordPage";
import SetupPasswordPage from "./pages/login/SetupPasswordPage";
import ProtectedRoute from "./pages/user/ProtectedRoute";

export const queryClient = new QueryClient();

export const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <QueryClientProvider client={queryClient}>
        <LoginPage />
      </QueryClientProvider>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <QueryClientProvider client={queryClient}>
        <ResetPasswordPage />
      </QueryClientProvider>
    ),
  },
  {
    path: "/setup-password",
    element: (
      <QueryClientProvider client={queryClient}>
        <SetupPasswordPage />
      </QueryClientProvider>
    ),
  },
  {
    path: "/",
    element: (
      <QueryClientProvider client={queryClient}>
        <ProtectedRoute>
          <UserPage />
        </ProtectedRoute>
      </QueryClientProvider>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
