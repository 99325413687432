import React, { useState, useEffect } from "react";
import {
  FormGroup,
  PasswordInput,
  Button,
  InlineNotification,
} from "@carbon/react";
import { ArrowRight } from "@carbon/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSetPassword } from "../../hooks/useSetPassword";
import validator from "validator";

export default function SetupPasswordForm({ resetToken }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const version = process.env.REACT_APP_VERSION;

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validation, setValidation] = useState({
    invalid: false,
    invalidText: "",
  });
  const [notification, setNotification] = useState({
    visibility: false,
    title: "",
    subtitle: "",
    kind: "",
  });

  const mutation = useSetPassword();

  const handleSubmitPassword = (e) => {
    e.preventDefault();

    if (!validator.isStrongPassword(newPassword)) {
      setValidation({
        invalid: true,
        invalidText: "authentication.validationErrors.passwordValidation",
      });
      return;
    }

    if (!validation.invalid && resetToken && newPassword) {
      mutation.mutate({ resetToken, password: newPassword });
    }
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      navigate("/");
    }
  }, [mutation.isSuccess, navigate]);

  useEffect(() => {
    if (mutation.isError) {
      if (mutation.error?.response?.status === 401) {
        setNotification({
          visibility: true,
          title: "authentication.notification.passwordError401.title",
          subtitle:
            "authentication.notification.passwordError401.passwordError",
          kind: "error",
        });

        return;
      }
      setNotification({
        visibility: true,
        title: "authentication.notification.requestError500.title",
        subtitle: "authentication.notification.requestError500.serverError",
        kind: "error",
      });
    }
  }, [mutation.isError, setValidation]);

  useEffect(() => {
    if (newPassword === confirmPassword) {
      setValidation({ invalid: false, invalidText: "" });
    } else {
      setValidation({
        invalid: true,
        invalidText: "authentication.validationErrors.differentPassword",
      });
    }
  }, [newPassword, confirmPassword, setValidation]);

  return (
    <FormGroup className="formGroup" legendText="">
      <h3>{t("authentication.newPasswordForm.title")}</h3>
      <form onSubmit={handleSubmitPassword} className="newPassword">
        <PasswordInput
          required
          id="one"
          labelText={t("authentication.common.password")}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          invalid={validation.invalid}
          invalidText={validation.invalidText ? t(validation.invalidText) : ""}
        />
        <PasswordInput
          required
          id="two"
          labelText={t("authentication.common.repPassword")}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {notification.visibility && (
          <InlineNotification
            title={t(notification.title)}
            subtitle={t(notification.subtitle)}
            kind={notification.kind}
            onClose={() =>
              setNotification({
                visibility: false,
                title: "",
                subtitle: "",
                kind: "",
              })
            }
            lowContrast={true}
            aria-label={t("authentication.notification.closeNotification")}
            className="notification"
          />
        )}
        <Button
          type="submit"
          className={`formButton ${version === "NZT" ? "nzt" : "jin"}`}
          renderIcon={ArrowRight}
          iconDescription="ArrowRight"
        >
          {t("authentication.common.continue")}
        </Button>
      </form>
    </FormGroup>
  );
}
