import { useCallback } from "react";
import { useAuthQuery } from "./useAuthQuery";

export const useData = () => {
  const selectFn = useCallback((data) => {
    const { fieldNames, gametypes, pokernetworks, rooms, records, users } =
      data?.data?.data;

    const gameTypesMap = new Map(
      Object.entries(gametypes).map(([key, value]) => [value, key])
    );
    const pokerNetworksMap = new Map(
      Object.entries(pokernetworks).map(([key, value]) => [value, key])
    );
    const roomMap = new Map(
      Object.entries(rooms).map(([key, value]) => [value, key])
    );
    const usersMap = new Map(
      Object.entries(users).map(([key, value]) => [value, key])
    );

    const pokerNetworksDataMap = new Map();
    const gameTypesDataMap = new Map();
    const loginsDataMap = new Map();

    const clubs = new Set();
    const leagues = new Set();
    const players = new Set();

    const mappedData = records.map((itemRecord) => {
      const rowObject = Object.fromEntries(
        fieldNames.map((itemField, indexField) => {
          if (itemField === "PokerNetwork") {
            return [itemField, pokerNetworksMap.get(itemRecord[indexField])];
          }
          if (itemField === "Room") {
            return [itemField, roomMap.get(itemRecord[indexField])];
          }
          if (itemField === "GameType") {
            return [itemField, gameTypesMap.get(itemRecord[indexField])];
          }
          if (itemField === "UserID") {
            return [itemField, usersMap.get(itemRecord[indexField])];
          }

          return [itemField, itemRecord[indexField]];
        })
      );

      setRowDataToMap(pokerNetworksDataMap, rowObject, "PokerNetwork", {
        gameTypes: "GameType",
        logins: "UserID",
      });

      setRowDataToMap(gameTypesDataMap, rowObject, "GameType", {
        pokerNetworks: "PokerNetwork",
        logins: "UserID",
      });

      setRowDataToMap(loginsDataMap, rowObject, "UserID", {
        pokerNetworks: "PokerNetwork",
        gameTypes: "GameType",
      });

      clubs.add(rowObject.ClubID);
      leagues.add(rowObject.LeagueID);
      players.add(rowObject.PlayerID);

      return rowObject;
    });

    const pokerNetworksData = mapToNewFormat(pokerNetworksDataMap);
    const gameTypesData = mapToNewFormat(gameTypesDataMap);
    const loginsData = mapToNewFormat(loginsDataMap);

    return {
      mappedData,
      gameTypes: Object.keys(gameTypesData),
      pokerNetworks: Object.keys(pokerNetworksData),
      logins: Object.keys(loginsData),
      loginsData,
      clubs: Array.from(clubs),
      leagues: Array.from(leagues),
      players: Array.from(players),
    };
  }, []);

  const response = useAuthQuery(
    ["getData"],
    {
      method: "POST",
      url: "/gameStats",
    },
    selectFn
  );

  return response;
};

const setRowDataToMap = (map, row, field, extraFields) => {
  if (!map.has(row[field])) {
    const extraParams = Object.fromEntries(
      Object.entries(extraFields).map(([key]) => [key, new Set()])
    );

    map.set(row[field], {
      clubs: new Set(),
      leagues: new Set(),
      players: new Set(),
      ...extraParams,
    });
  }

  const mapValue = map.get(row[field]);

  Object.entries(extraFields).forEach(([key, value]) => {
    mapValue[key].add(row[value]);
  });

  mapValue.clubs.add(row.ClubID);
  mapValue.leagues.add(row.LeagueID);
  mapValue.players.add(row.PlayerID);
};

const mapToNewFormat = (originalMap) => {
  const result = {};

  originalMap.forEach((value, key) => {
    result[key] = {};

    Object.entries(value).forEach(([field, fieldSet]) => {
      result[key][field] = Array.from(fieldSet);
    });
  });

  return result;
};
