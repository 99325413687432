import React, { useState, useEffect, useMemo, useRef } from "react";
import Toolbar from "../../components/statsUser/toolbar/Toolbar";
import IncomeChart from "../../components/statsUser/chart/IncomeChart";
import DataTable from "../../components/statsUser/dataTable/DataTable";
import "./user.scss";
import UserHeader from "../../components/header/UserHeader";
import { useData } from "../../hooks/useData";
import { filteredDataMapper } from "../../mappers/filteredDataMapper";
import { useTranslation } from "react-i18next";
import "devextreme/dist/css/dx.light.css";

export default function UserPage() {
  const { t, i18n } = useTranslation();
  const dataGridRef = useRef(null);

  const [filterOptions, setFilterOptions] = useState({
    dateFromTo: [],
    selectedPokerNetworks: [],
    selectedGameTypes: [],
    selectedLogins: [],
    selectedPIDs: [],
    selectedClubs: [],
    selectedLeagues: [],
  });

  const [responseData, setResponseData] = useState({
    mappedData: [],
    gameTypes: [],
    pokerNetworks: [],
    logins: [],
    loginsData: {},
    clubs: [],
    leagues: [],
    players: [],
  });

  const { isPending, isSuccess, data } = useData();

  useEffect(() => {
    if (isSuccess) {
      setResponseData(data);
    }
  }, [isSuccess, data]);

  const {
    mappedData,
    gameTypes,
    pokerNetworks,
    logins,
    loginsData,
    clubs,
    leagues,
    players,
  } = responseData;

  const filteredData = useMemo(
    () => filteredDataMapper(mappedData, filterOptions),
    [mappedData, filterOptions]
  );

  useEffect(() => {
    document.title = t("documentTitle");
  }, [i18n.language, t]);

  return (
    <div className="main">
      <UserHeader />
      <div className="main_body">
        <Toolbar
          setFilterOptions={setFilterOptions}
          gameTypes={gameTypes}
          pokerNetworks={pokerNetworks}
          logins={logins}
          loginsData={loginsData}
          clubs={clubs}
          leagues={leagues}
          players={players}
          dataGridRef={dataGridRef}
          isPending={isPending}
        />
        <IncomeChart filteredData={filteredData} isPending={isPending} />
        <DataTable
          filteredData={filteredData}
          isSuccess={isSuccess}
          ref={dataGridRef}
        />
      </div>
    </div>
  );
}
