import { useMutation } from "@tanstack/react-query"
import {createAxios} from './middleware'

export const useSetPassword = () =>{   
   const axios = createAxios();

   return useMutation({
      mutationFn: async (query) => {
        return await axios.post('/setupPwd', query)
      },
      onSuccess: (data) => {
         localStorage.setItem('authToken', data?.data?.data?.token)
       },
    })
}