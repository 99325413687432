import i18n from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import EN from "./locales/en/translation.json";
import ZH from "./locales/zh/translation.json";
import PT from "./locales/pt/translation.json";
import RU from "./locales/ru/translation.json";

const resources = {
  en: { translation: EN },
  zh: { translation: ZH },
  pt: { translation: PT },
  ru: { translation: RU },
};

const storedLanguage = localStorage.getItem("i18nextLng");
const systemLanguage = navigator.language || "en";

const languageToUse = storedLanguage || systemLanguage;

i18n
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    debug: true,
    lng: languageToUse,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i"],
    },
  });
export default i18n;
